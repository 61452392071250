<template lang="pug">
.pools(v-infinite-scroll="fetchData" infinite-scroll-disabled="isPoolBusy" :infinite-scroll-distance="scrollDistance")
  Table.pool-pool.app-table(:columns="poolCols" :data="poolList", border)
    template(slot-scope="{ row, index }" slot="poolId")
      span {{ row.poolId }}

    template(slot-scope="{ row, index }" slot="game")
      Avatar(:src="getGameThumb(row.game, userInfo)" shape="square" size='small' :style="{marginRight: '8px'}")
      span {{ row.game.name }}

    template(slot-scope="{ row, index }" slot="category")
      span {{ $t(`${row.category}`) }}

    template(slot-scope="{ row, index }" slot="prize")
      span {{ currencyFormat(row.prize, { ...userInfo, digits: 0 }) }}

    template(slot-scope="{ row, index }" slot="releaseLevel")
      span {{ currencyFormat(row.releaseLevel, { ...userInfo, digits: 0 }) }}

    template(slot-scope="{ row, index }" slot="stakeRange")
      span {{ currencyFormat(row.minStake, { ...userInfo, digits: 0 }) }}
      Icon(type="md-arrow-forward", :style="{margin: '0 5px'}")
      span {{ currencyFormat(row.maxStake, { ...userInfo, digits: 0 }) }}

    template(slot-scope="{ row, index }" slot="gameClass")
      span {{ $t(`class${row.gameClass}`) }}

    template(slot-scope="{ row, index }" slot="actions")
      //- Tooltip(placement="top", :content="$t('Edit pool')")
      Button(
        type="success"
        size='small'
        icon="md-create"
        v-if="functions.poolUpdate"
        @click="editPool(row)"
      )
  Col.table-loading-col(span='24', v-if="poolListLoading")
    Spin(fix, :style="{ backgroundColor: 'transparent', marginTop: '0' }")
      Icon(type="ios-loading" size=18 class="spin-icon")

  PoolEditor(
    :form-data="poolData2Update",
    edit-type="edit"
    :on-update="onPoolUpdate"
    :visible="poolEditorVisible"
    :edit-type="poolEditType"
    :on-close="closePoolEditor"
  )

</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Currency from '~m/currency'
import Scroll from '~m/scroll'
import NumberUtil from '~m/number-util'
import Thumbnail from '~m/thumbnail'
import PoolEditor from '~c/pool-editor'
import to from 'await-to-js'
import _ from 'lodash'
export default {
  name: 'operator-pool',
  data () {
    return {
      poolData2Update: null,
      poolEditorVisible: false
    }
  },
  props: {
    poolData: Object
  },
  mixins: [Currency, Scroll, NumberUtil, Thumbnail],
  components: { PoolEditor },
  watch: {
  },
  computed: {
    ...mapGetters('auth', ['userInfo']),
    ...mapGetters('operatorPool', { poolList: 'getListData', poolOne: 'getOneData', poolListLoading: 'getLoadingStatus', hasNextPool: 'hasNext' }),
    ...mapGetters('operator', {
      allOperatorsForSelector: 'getAllForSelector'
    }),

    allOperators () {
      return this.allOperatorsForSelector.filter(op => op.actived)
    },
    functions () {
      return this.userInfo.sidebarMap['pool'].functions
    },
    poolCols () {
      return [
        {
          slot: 'poolId',
          width: 65,
          align: 'center'
        },
        {
          title: this.$t('Game info'),
          slot: 'game',
          ellipsis: true
        },
        {
          title: this.$t('Pool type'),
          slot: 'category',
          width: 100
        },
        {
          title: this.$t('Pool prize'),
          slot: 'prize'
        },
        {
          title: this.$t('Pool bet count'),
          key: 'betCount'
        },
        {
          title: this.$t('Pool release amount'),
          slot: 'releaseLevel',
          width: 150
        },
        {
          title: this.$t('Pool stake range'),
          slot: 'stakeRange'
        },
        {
          title: this.$t('Game class'),
          slot: 'gameClass'
        },
        {
          title: this.$t('Pool switch'),
          key: 'actived',
          // 無法用 slot template 取得點擊後的 instance 用 render 取代
          render: this.renderSwitch,
          align: 'center',
          width: 85
        },
        {
          title: this.$t('Table actions'),
          slot: 'actions',
          width: 66
        }
      ]
    },
    isPoolBusy () {
      return this.poolListLoading || !this.hasNextPool
    }
  },
  methods: {
    ...mapActions('operatorPool', { updatePool: 'update', findPools: 'find', resetPool: 'resetList', createPool: 'create' }),
    // 動態 switch 無法用 slot 模式嵌入 table row 中，以 render 方式實做
    renderSwitch (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.actived,
          disabled: !this.functions.poolUpdate
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                actived: !row.actived
              }
              dispatch('operatorPool/update', { id: row.id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    },

    fetchData () {
      const { operatorId, providerId, gameClass } = this.poolData
      const query = { operator: operatorId, provider: providerId, category: 'jp4', gameClass }
      this.findPools(query)
    },

    editPool (row) {
      this.poolData2Update = _.cloneDeep(row)
      this.poolEditorVisible = true
    },

    async onPoolUpdate () {
      if (!this.poolData2Update.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closePoolEditor()
      }

      let dataUpdate = _.pick(this.poolData2Update, ['minStake', 'maxStake', 'releaseLevel', 'odds', 'category', 'initialLevel', 'actived', 'prize', 'winningRatio'])
      // 強轉數字類型
      this.parseObjectNumber(dataUpdate)
      dataUpdate = { data: dataUpdate }

      let method
      switch (this.poolEditType) {
        case 'edit':
          method = this.updatePool
          dataUpdate.id = this.poolData2Update.poolId
          break
      }

      let [err] = await to(method(dataUpdate))
      if (err) {
        console.log(err)
        const msg = this.poolEditType === 'edit' ? 'Edit pool error' : 'Create pool error'
        return this.$Message.error(this.$t(msg))
      }

      this.$Message.success(this.$t('Edit pool seccess'))
      this.closePoolEditor()
    },

    closePoolEditor () {
      this.poolEditorVisible = false
      setTimeout(() => {
        this.poolData2Update = null
      }, 500)
    }
  },

  mounted () {
    this.fetchData()
  },

  beforeDestroy () {
    this.resetPool()
  }
}
</script>
<style lang="sass" scoped>
.ivu-table-expanded-cell
  padding: 16px
</style>
