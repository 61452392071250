<template lang="pug">
.content(
  v-infinite-scroll="fetchData",
  infinite-scroll-disabled="isUserBusy",
  :infinite-scroll-distance="scrollDistance",
  infinite-scroll-immediate-check="false"
)
  Content.app-content
    Row
      Col
        span(
          style="margin: 6px 8px; float: left;"
        ) {{ $t('BetRecordText.Operator type') }}
        Select(
          :disabled="isLoading"
          v-model="operatorType"
          style="width:90px; margin-right: 16px; float: left;"
          filterable
        )
          Option(v-for="item in operatorTypeList" :value="item.value" :key="item.value") {{ item.label }}

    Row(:style="{marginTop: '16px'}")
      Col(span="24" v-if="poolList.length || !isLoading")
        AppTable.app-table(:columns="columns" :data="operatorPoolList" border)
          template(slot-scope="{ row, index }" slot="operator")
            span {{ row.username }} /  {{ row.fullname }}

          template(slot-scope="{ row, index }" slot="parentOperator")
            span(v-if="row.parentOperator") {{ row.parentOperator.username }} / {{ row.parentOperator.fullname }}

          template(slot-scope="{ row, index }" slot="jp-mini")
            .pool-number
              span(:class="{ over: row.pool['jp-mini'].prize > row.pool['jp-mini'].releaseLevel }") {{ currencyFormat(row.pool['jp-mini'].prize, { noSymbol: true }) }}
              span(style="margin: 0 6px") /
              span {{ currencyFormat(row.pool['jp-mini'].releaseLevel, { noSymbol: true, digits: 0 }) }}
            i-switch.actions(
              style="margin: 4px 0 0 4px;"
              v-model="row.pool['jp-mini'].actived"
              @on-change="togglePool(row.pool['jp-mini'])"
              size="small"
            )
            Button.actions(
              type="success"
              size='small'
              icon="md-create"
              @click="editPool(row.pool['jp-mini'])"
            )

          template(slot-scope="{ row, index }" slot="jp-minor")
            .pool-number
              span(:class="{ over: row.pool['jp-minor'].prize > row.pool['jp-minor'].releaseLevel }") {{ currencyFormat(row.pool['jp-minor'].prize, { noSymbol: true }) }}
              span(style="margin: 0 6px") /
              span {{ currencyFormat(row.pool['jp-minor'].releaseLevel, { noSymbol: true, digits: 0 }) }}
            i-switch.actions(
              style="margin: 4px 0 0 4px;"
              v-model="row.pool['jp-minor'].actived"
              @on-change="togglePool(row.pool['jp-minor'])"
              size="small"
            )
            Button.actions(
              type="success"
              size='small'
              icon="md-create"
              @click="editPool(row.pool['jp-minor'])"
            )

          template(slot-scope="{ row, index }" slot="jp-major")
            .pool-number
              span(:class="{ over: row.pool['jp-major'].prize > row.pool['jp-major'].releaseLevel }") {{ currencyFormat(row.pool['jp-major'].prize, { noSymbol: true }) }}
              span(style="margin: 0 6px") /
              span {{ currencyFormat(row.pool['jp-major'].releaseLevel, { noSymbol: true, digits: 0 }) }}
            i-switch.actions(
              style="margin: 4px 0 0 4px;"
              v-model="row.pool['jp-major'].actived"
              @on-change="togglePool(row.pool['jp-major'])"
              size="small"
            )
            Button.actions(
              type="success"
              size='small'
              icon="md-create"
              @click="editPool(row.pool['jp-major'])"
            )

          template(slot-scope="{ row, index }" slot="jp-grand")
            .pool-number
              span(:class="{ over: row.pool['jp-grand'].prize > row.pool['jp-grand'].releaseLevel }") {{ currencyFormat(row.pool['jp-grand'].prize, { noSymbol: true }) }}
              span(style="margin: 0 6px") /
              span {{ currencyFormat(row.pool['jp-grand'].releaseLevel, { noSymbol: true, digits: 0 }) }}
            i-switch.actions(
              style="margin: 4px 0 0 4px;"
              v-model="row.pool['jp-grand'].actived"
              @on-change="togglePool(row.pool['jp-grand'])"
              size="small"
            )
            Button.actions(
              type="success"
              size='small'
              icon="md-create"
              @click="editPool(row.pool['jp-grand'])"
            )

      Col(span='24', class="table-loading-col" v-if="isLoading")
        Spin(fix)
          Icon(type="ios-loading", size=18 class="spin-icon")

  PoolEditor(
    :form-data="poolData4Update",
    edit-type="edit"
    :on-update="onPoolUpdate"
    :visible="poolEditorVisible"
    :on-close="closePoolEditor"
  )

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OperatorPools from '~v/pool/operator-pools'
import AppTable from '~c/app-table'
import PoolEditor from '~c/pool-editor'
import Scroll from '~m/scroll'
import RouterEvents from '~m/router-events'
import Currency from '~m/currency'
import NumberUtil from '~m/number-util'
import to from 'await-to-js'

export default {
  name: 'pool',
  components: {
    OperatorPools,
    Scroll,
    AppTable,
    PoolEditor
  },

  mixins: [Scroll, Currency, NumberUtil, RouterEvents],

  // view 裡大部份的 data 是用來做過濾器用的，主要是同步 url 上的參數，部份 data 是做過濾器的暫存
  data () {
    return {
      operatorType: 'prod',
      poolData4Update: null,
      poolEditorVisible: false,
      operatorTypeList: [
        {
          label: this.$t('BetRecordText.Prod operator'),
          value: 'prod'
        },
        {
          label: this.$t('BetRecordText.Beta operator'),
          value: 'beta'
        }
      ],
      columns: [
        {
          title: 'ID',
          key: 'id',
          width: 75,
          align: 'center'
        },
        {
          title: this.$t('Operator'),
          slot: 'operator',
          width: 180
        },
        {
          title: this.$t('Parent operator'),
          slot: 'parentOperator',
          width: 180
        },
        {
          title: `${this.$t('jp-mini')} / ${this.$t('Pool release amount')}`,
          slot: 'jp-mini',
          minWidth: 120
        },
        {
          title: `${this.$t('jp-minor')} / ${this.$t('Pool release amount')}`,
          slot: 'jp-minor',
          minWidth: 120
        },
        {
          title: `${this.$t('jp-major')} / ${this.$t('Pool release amount')}`,
          slot: 'jp-major',
          minWidth: 120
        },
        {
          title: `${this.$t('jp-grand')} / ${this.$t('Pool release amount')}`,
          slot: 'jp-grand',
          minWidth: 120
        }
      ]
    }
  },

  watch: {
    // 監聽網址的變化進行 data 重設
    async '$route' (nv, ov) {
      if (nv.params.provider === ov.params.provider) return this.fetchData(true)
      // pid 不一樣要更新 provider list
      this.resetSelector()
    }
  },

  // 所有內容的多數 data 來自於 vuex 的 state 以 module 裡的 getter 的方式嵌入 computed 中
  computed: {
    ...mapGetters('operatorPool', {
      poolList: 'getListData',
      isLoading: 'getLoadingStatus',
      hasNext: 'hasNext'
    }),

    // 遊戲內容是否在讀取
    isBusy () {
      return this.isLoading || !this.hasNext
    },

    isPoolOneBusy () {
      return this.poolOneLoading
    },

    operatorPoolList () {
      return this.poolList.filter(p => this.operatorType === 'prod' ? !p.isBeta : p.isBeta)
    }
  },

  methods: {
    ...mapActions('operatorPool', { findPool: 'find', resetPool: 'reset', updatePoolOne: 'updatePoolOne' }),
    ...mapActions('pool', { updatePool: 'update' }),

    // 向 API 取得 data
    // infinity scroll 需綁定
    fetchData (reset = false) {
      if (reset) {
        this.resetPool({})
      }

      if (this.isBusy) return
      this.findPool({ currency: this.currency, rows: 42 })
    },

    // 依據 route 重設所有過濾條件
    async resetSelector () {
      this.fetchData(true)
    },

    renderSwitch (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.actived
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                actived: !row.actived
              }
              dispatch('pool/update', { id: row.id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    },

    editPool (row) {
      this.poolData4Update = _.cloneDeep(row)
      this.poolEditorVisible = true
    },
    async togglePool (pool) {
      let dataUpdate = _.pick(pool, ['minStake', 'maxStake', 'releaseLevel', 'odds', 'category', 'initialLevel', 'actived', 'prize', 'poolRate', 'winningRatio'])

      this.parseObjectNumber(dataUpdate)

      let [err] = await to(this.updatePool({ id: pool.poolId, data: dataUpdate }))
      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Edit pool error'))
      }

      this.poolData = {
        ...this.poolData,
        ...dataUpdate
      }

      // this.$Message.success(this.$t('Edit pool seccess'))
    },
    async onPoolUpdate () {
      if (!this.poolData4Update.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closePoolEditor()
      }

      let dataUpdate = _.pick(this.poolData4Update, ['minStake', 'maxStake', 'releaseLevel', 'odds', 'category', 'initialLevel', 'actived', 'prize', 'poolRate', 'winningRatio'])

      // 強轉數字類型
      this.parseObjectNumber(dataUpdate)

      let [err, poolData] = await to(this.updatePool({ id: this.poolData4Update.poolId, data: dataUpdate }))
      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Edit pool error'))
      }

      this.$Message.success(this.$t('Edit pool seccess'))

      this.updatePoolOne(poolData)
      this.closePoolEditor()
      // this.fetchData(true)
    },

    closePoolEditor () {
      this.poolEditorVisible = false
      setTimeout(() => {
        this.poolData4Update = null
      }, 500)
    }
  },
  async mounted () {
    this.resetSelector()
  },
  beforeDestroy () {
    this.resetPool()
  }
}

</script>

<style lang="sass">
.over
  // color: #ed4014
  color: #19be6b
.actions
  margin-top: 4px
  float: right
.tag
  margin-left: 4px
  font-size: 11px
  height: 18px
  line-height: 18px
  padding: 0 4px
.pool-number
  line-height: 24px
  float: left

</style>
